import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import 'bootstrap/dist/css/bootstrap.min.css'; // React Bootstrap
import  { Auth } from 'aws-amplify'; // Auth
//import App from './App';
import './App.css';
import Navigation from './Navigation';
import ScenarioHistoryTable from './ScenarioHistoryTable';
import {UserProvider} from './UserProvider';
import ServerActionHandler from './ServerActionHandler';
import ec2InstanceManager from './ec2InstanceManager';
import ModelProcesses from './ModelProcesses';
import ScenarioExecution from  './scenarioexecution';
import amplifyConfigure from './amplify-configure';
//import awsconfig from './aws-exports';

// performance monitoring
//import reportWebVitals from './reportWebVitals';

//Amplify.configure(awsconfig)

amplifyConfigure();

// get the current config object
//const currentConfig = Auth.configure();

var userName = '';

function authenticatedUserHandler(user){
  
    //console.log('OKTA info=',user);

    console.log('CognitoAccessToken jwt token=',user.signInUserSession.idToken.jwtToken);
    const token = user.signInUserSession.idToken.jwtToken;

    //console.log('Okta user name=',user.signInUserSession.idToken.payload.identities[0].userId);  
    userName = user.signInUserSession.idToken.payload.identities[0].userId;
    userName = userName.replace(/_/g, ' ');

    const userParameters = {
      "userName": userName,
      "token": user.signInUserSession.idToken.jwtToken,
      "tokenExpiry": user.signInUserSession.idToken.payload.exp
    };

    /* * /
    const expiryTimestampInSeconds = user.signInUserSession.idToken.payload.exp;
    console.log('expiryTimestampInSeconds=',expiryTimestampInSeconds);

    const expiryTimestamp = expiryTimestampInSeconds*1000;
    const expiryDateTime = new Date(expiryTimestamp);
    console.log('expiry datetime=',expiryDateTime);
    
    const currentDate = new Date();
    console.log('current datetime=',currentDate);
    
    const currentTimestamp = new Date().getTime();
    const currentTimestampInSeconds = currentTimestamp/1000;
    console.log('currentTimestampInSeconds=',currentTimestampInSeconds);
    const difference = expiryTimestampInSeconds - currentTimestampInSeconds;
    console.log('difference=',difference);

    if(expiryTimestampInSeconds<=currentTimestampInSeconds){
      // trigger re-login
      console.log('token expired');
    }
    /* */

    ReactDOM.render(
      <React.StrictMode>
          <div className="App">
            <Fragment>
                <UserProvider>
                  <Navigation userParametersArray={userParameters}/>
                  <ScenarioHistoryTable token={userParameters.token} />
                </UserProvider>
            </Fragment>
          </div>
      </React.StrictMode>,
      document.getElementById('root')
    );  

    // **********************************************************************************
    // for testing
    //ServerActionHandler(token); // 
    //ModelProcesses(token);
    //ScenarioExecution(token);
    //ec2InstanceManager(token);
  }

function loginStateHandler(){

  const queryParams = new URLSearchParams(window.location.search);
  //console.log('queryParams=',queryParams);
  const signinstate = queryParams.get('signinstate');
    if((signinstate==="manual")||(signinstate==="signedout")){
      // manual sign in
      successfulSignOutHandler()
    }else{
      // perform automatic sign in via OKTA
      Auth.federatedSignIn({provider: "Okta"})
    }

}

// validates whether user is already signed into the application
Auth.currentAuthenticatedUser({
  bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
}).then(
  // user already signed in
  user => authenticatedUserHandler(user)
  )
.catch(
    // user not signed in - perform log in state handling
    err => loginStateHandler()

    // perform automatic sign in via OKTA
    //err => Auth.federatedSignIn({provider: "Okta"})
  );

function RenderSignInUser() {
  return (
    <div className="App">
      <header className="App-header">
        <p>You are signed out of the application and can either close the browser window or sign in below</p>
        <button onClick={() => Auth.federatedSignIn({provider: "Okta"}) }>Sign In via OKTA SSO</button>
      </header>
    </div>
  );
}

function successfulSignOutHandler(){
  ReactDOM.render(
    <React.StrictMode>
      <RenderSignInUser />
    </React.StrictMode>,
    document.getElementById('root')
  );  
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

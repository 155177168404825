// https://blog.logrocket.com/a-deep-dive-into-react-context-api/
import React, { createContext, useState } from "react";
import { Auth } from 'aws-amplify';
import amplifyConfigure from './amplify-configure';

amplifyConfigure(); // invoke amplify configure

async function SignOut() {
  try {
      // global sign out of all devices
      //await Auth.signOut({ global: true });

      // sign out of current device
      await Auth.signOut();
      console.log('Auth signout ok');  
  } catch (error) {
      console.log('Error signing out. Message details: ', error);
  }
}

const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);

function UserProvider({ children }) {

  //console.log('UserProvider user=',children.userParametersArray.userName);
  //console.log('UserProvider token=',children.userParametersArray.token);
  //console.log('UserProvider children=',children);

  const [userDetails, setUserDetails] = useState({
    isLoggedIn: true,
    appContentReady: false,
    username: "",
    token: "",
    tokenExpiry: "",
    oldestReinsYear: "",
    latestReinsYear: "",
    oldestAdmYear: "",
    latestAdmYear: "",
    oldestHccCurveYear: "",
    oldestAtheniumCurveYear: "",
    defaultCommoditiesPicklist: [],
    defaultStatesPicklist: [],
    defaultInsurancePlansPicklist: [],
    defaultCommoditiesArray: [],
    defaultStatesArray: [],
    defaultInsurancePlansArray: [],
    commoditiesArray: [],
    statesArray: [],
    insurancePlansArray: [],
    mainServerStatus: "",
    workerServerStatus: "",
    login: () => {
        //userDetails.isLoggedIn = true;
        //console.log('login userDetails isLoggedIn=',userDetails.isLoggedIn);
    },
    logout: () => {
      // validates whether user is already signed into the application
      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(
        // user is signed in - perform sign out
        SignOut()
        )
      .catch(
        // User not logged in
        );
      userDetails.isLoggedIn = false;
      userDetails.username = '';
      userDetails.token = '';
    }
  });

  return (
    <UserContext.Provider value={userDetails}>
      <UserDispatchContext.Provider value={setUserDetails}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserDispatchContext };
// Auth
import Amplify from 'aws-amplify';
import amplifyParameters from './amplify-parameters-dev.json';
import appParameters from './app-parameters-dev';

function amplifyConfigure(){

    Amplify.configure({
        Auth: {
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            // obtain from Cognito -> Identity Pool -> select specific Identity Pool -> Sample code page
            // or click Edit identity pool
            identityPoolId: amplifyParameters.identityPoolId,
    
            // REQUIRED - Amazon Cognito Region
            region: amplifyParameters.region,
    
            // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
            // Required only if it's different from Amazon Cognito Region
            //identityPoolRegion: 'XX-XXXX-X',
    
            // OPTIONAL - Amazon Cognito User Pool ID
            // obtain from Cognito -> select specific User Pool ->General settings page
            userPoolId: amplifyParameters.userPoolId,
    
            // Amazon Cognito Web Client ID (26-char alphanumeric string)
            // obtain from Cognito -> select specific User Pool -> App integration -> App client settings screen
            // or General Settings -> App clients
            // NOTE: the userPoolId + userPoolWebClientId must be stored as an entry under the Authentication providers in the above Identity Pool ID
            userPoolWebClientId: amplifyParameters.userPoolWebClientId, // User Pool: Actuarial Web Apps SAML | App client name: amplify-crop-modeling-app-saml-non-prod
    
            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,
    
            // OPTIONAL - Configuration for cookie storage
            // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
            //cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            //    domain: '.yourdomain.com',
            // OPTIONAL - Cookie path
            //    path: '/',
            // OPTIONAL - Cookie expiration in days
            //    expires: 365,
            // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            //    sameSite: "strict" | "lax",
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            //    secure: true
            //},
    
            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            //authenticationFlowType: 'USER_PASSWORD_AUTH',
    
            // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
            //clientMetadata: { myCustomKey: 'myCustomValue' },
    
             // OPTIONAL - Hosted UI configuration
            oauth: {
                domain: amplifyParameters.domain,
                scope: amplifyParameters.scope, // don't use invalid scopes or the auth will loop over and over
    
                redirectSignIn: appParameters.appDomainName,
                redirectSignOut: appParameters.appDomainName + "?signinstate=signedout",
               
                // crop-modeling-app
                //redirectSignIn: 'https://crop-modeling-dev.tmhcc.com/',
                //redirectSignOut: 'https://crop-modeling-dev.tmhcc.com//logout',
    
                responseType: 'token' // note that REFRESH token will only be generated when the responseType is code
            }
        }
    });

}
export default amplifyConfigure;
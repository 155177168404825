import React, { Fragment, useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import {UserContext} from './UserProvider';
//import { Button,Navbar,Nav,NavItem,NavDropdown,MenuItem } from 'react-bootstrap';

function Header(props){

    //const handleSelect = (eventKey) => alert(`${eventKey}`);

    // obtain React Context data
    const userDetails = useContext(UserContext);

    // store parameters in react context
    //console.log('nav user=',props.userParametersArray.userName);
    //console.log('nav token=',props.userParametersArray.token);
    userDetails.username = props.userParametersArray.userName;
    userDetails.token = props.userParametersArray.token;
    userDetails.tokenExpiry = props.userParametersArray.tokenExpiry;

    /*
    const loginHandler = () => {
      userDetails.login(); // invoke context method
    };
    */

    const logoutHandler = () => {
      userDetails.logout(); // invoke logout context method
      userDetails.username = '';
    };

    return (
      < Fragment>
        <Navbar>
        <Navbar.Brand>Crop Modeling Manager</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
            {userDetails.isLoggedIn ? 'Signed in as' : 'Signed Out' }
            </Navbar.Text>
        </Navbar.Collapse>
        <NavDropdown alignRight title={props.userParametersArray.userName} id="collasible-nav-dropdown">
            <NavDropdown.Item onClick={logoutHandler} >Logout</NavDropdown.Item>
        </NavDropdown>
        </Navbar>
      </Fragment>

    )
}
/*
      onClick={() => { userDetails.logout() }} 
          <NavDropdown.Item onClick={() => { userDetails.logout() }} >Logout</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="">Server Management</NavDropdown.Item>
*/
export default Header;
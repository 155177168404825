import React, { Component, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Container from 'react-bootstrap/Container';
import Form from "react-bootstrap/Form";
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner'
import './index.css';
import {UserContext} from './UserProvider';
import axios from 'axios';
import appParameters from './app-parameters-dev';

function tokenSessionCheck(tokenExpiry){

    //console.log('tokenExpiry=',tokenExpiry);
    const currentTimestampInSeconds = new Date().getTime()/1000;
    var sessionExpired = false;
    if(tokenExpiry<=currentTimestampInSeconds){
      //token expired - trigger re-login
      sessionExpired = true; 
      window.location.reload(true);
    }
    return sessionExpired;
}

export default class PrepareCreateScenario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            //cropsPicklistOpen: false,
            appContentReady: false,
            createScenarioErrorMessage: "",
            optionalPicklistLoading: false,
            showSubmitScenarioSpinner: false,
            submitScenarioButtonIsDisabled: false,
            nondefaultAdmYearChosen: "",
            scenarioName: "",
            reinsuranceYear: "",
            admYear: "",
            yieldCurveYear: "",
            priceCurveYear: "",
            hccCurveYear: "",
            commoditiesPicklist: [],
            chosenCommodities: "",
            chosenAllCommodities: "",
            chosenCommoditiesCodeArray: [],
            chosenCommoditiesNameArray: [],
            statesPicklist: [],
            chosenStates: "",
            chosenAllStates: "",
            chosenStatesCodeArray: [],
            chosenStatesNameArray: [],
            insurancePlansPicklist: [],
            chosenInsurancePlans: "",
            chosenAllInsurancePlans: "",
            chosenInsurancePlansNameArray: [],
            chosenInsurancePlansCodeArray: []
        };
        this.baseState = this.state     // preserve the initial state in a new object
        this.initializeCreateScenarioModal = this.initializeCreateScenarioModal.bind(this); // add 9/3
        this.closeCreateScenarioModal = this.closeCreateScenarioModal.bind(this);
        this.handleOptionalParametersPicklistUpdates = this.handleOptionalParametersPicklistUpdates.bind(this);
        this.handleFormInputChange = this.handleFormInputChange.bind(this);
        this.handleScenarioCreation = this.handleScenarioCreation.bind(this);
    }

    // prepare the Context
    static contextType = UserContext

    initializeCreateScenarioModal =() => {

        this.setState(this.baseState); // reset props to defaults
        this.setState({ modalShow: true });
        //console.log('init nonDefaultAdmYearChosen=',this.state.nonDefaultAdmYearChosen);
        
        // access the Context
        const appContext = this.context

        const sessionExpired = tokenSessionCheck(appContext.tokenExpiry);
        console.log('sessionExpired=',sessionExpired);

        // store the default picklist values
        this.setState({ 'reinsuranceYear': appContext.latestReinsYear });
        this.setState({ 'admYear': appContext.latestAdmYear });
        this.setState({ 'yieldCurveYear': appContext.latestReinsYear });
        this.setState({ 'priceCurveYear': appContext.latestReinsYear });
        this.setState({ 'hccCurveYear': appContext.latestReinsYear });
        //console.log('this.state.reinsuranceYear=',this.state.reinsuranceYear);
    }

    //closeCreateScenarioModal = () => this.setState({ modalShow: false });
    closeCreateScenarioModal = () => {
        this.setState({ 'nonDefaultAdmYearChosen': 'N' });
        //this.context.activePicklistAdmYear = this.state.admYear;
        //console.log('this.context.activePicklistAdmYear stored as ', this.state.admYear);
        this.setState({ modalShow: false });
    }

    handleOptionalParametersPicklistUpdates =() => {
        console.log('handleOptionalParametersPicklistUpdates');
        //this.setState({ 'chosenCommodities': xxx });
    }

    //handleFormInputChange = e => this.setState({ [e.target.name]: e.target.value }); // store form value in related props field

    handleFormInputChange = e => {
        //console.log('change state triggered');
        //console.log(e.target.name);
        //console.log(e.target.value);
        this.setState({ [e.target.name]: e.target.value });
        //console.log(this.state);
    }

    handleScenarioCreation =() => {
        this.setState({ submitScenarioButtonIsDisabled: true });
        this.setState({showSubmitScenarioSpinner: true});
        let scenarioReady = true;

        // validation
        var errorMessage = "";

        if(this.state.scenarioName===''){
            scenarioReady = false;
            errorMessage = 'Scenario Name is required';
        }
        if(this.state.reinsuranceYear===''){
            scenarioReady = false;
            errorMessage = 'Reinsurance Year is required';
        }
        if(this.state.admYear===''){
            scenarioReady = false;
            errorMessage = 'ADN Year is required';
        }
        if(this.state.yieldCurveYear===''){
            scenarioReady = false;
            errorMessage = 'Yield Curve Year is required';
        }
        if(this.state.priceCurveYear===''){
            scenarioReady = false;
            errorMessage = 'Price Curve Year is required';
        }
        if(this.state.hccCurveYear===''){
            scenarioReady = false;
            errorMessage = 'HCC Curve Year is required';
        }

        if(this.state.nonDefaultAdmYearChosen==="Y"){
            // validate selected Commodities
            var commodityName, missingCommodityNames = "";
            console.log('chosenCommodities=',this.state.chosenCommoditiesCodeArray.length);
            console.log('commoditiesPicklist=',this.state.commoditiesPicklist.length);
            for (let i=0; i < this.state.chosenCommoditiesCodeArray.length; i++) {
                commodityName = this.state.chosenCommoditiesNameArray[i];
                console.log('chosenCommodity code=',this.state.chosenCommoditiesCodeArray[i]);
                for (let x=0; x < this.state.commoditiesPicklist.length; x++) {
                    console.log('commoditiesPicklist value=',this.state.commoditiesPicklist[x].value);
                    if(this.state.chosenCommoditiesCodeArray[i]===this.state.commoditiesPicklist[x].value){
                        commodityName = "";
                        break;
                    }
                }
                if(commodityName!==""){
                    console.log(commodityName,' not found');
                    missingCommodityNames = missingCommodityNames + commodityName + "; ";
                }
            }
            console.log('missingCommodityNames=',missingCommodityNames);
            var cropErrorMessage = "Crop(s) not found in selected ADM Year (remove invalid selections and try again): ";
            if(missingCommodityNames!==""){
                scenarioReady = false;
                errorMessage = cropErrorMessage + missingCommodityNames;
            }
            console.log('commoditiesPicklist=',this.state.commoditiesPicklist);

            // validate selected Insurance Plans
            var insurancePlanName, missinginsurancePlanNames = "";
            //console.log('chosenInsurancePlansCodeArray=',this.state.chosenInsurancePlansCodeArray.length);
            console.log('insurancePlansPicklist=',this.state.insurancePlansPicklist.length);
            for (let i=0; i < this.state.chosenInsurancePlansCodeArray.length; i++) {
                insurancePlanName = this.state.chosenInsurancePlansNameArray[i];
                //console.log('insurancePlanName=',this.state.chosenInsurancePlansNameArray[i]);
                //console.log('chosenInsurancePlansCodeArray code=',this.state.chosenInsurancePlansCodeArray[i]);
                for (let x=0; x < this.state.insurancePlansPicklist.length; x++) {
                    //console.log('insurancePlansPicklist value=',this.state.insurancePlansPicklist[x].value);
                    if(this.state.chosenInsurancePlansCodeArray[i]===this.state.insurancePlansPicklist[x].value){
                        insurancePlanName = "";
                        break;
                    }
                }
                if(insurancePlanName!==""){
                    missinginsurancePlanNames = missinginsurancePlanNames + insurancePlanName + "; ";
                }
            }
            var plansErrorMessage = "Insurance Plan(s) not found in selected ADM Year (remove invalid selections and try again): ";
            if(missinginsurancePlanNames!==""){
                scenarioReady = false;
                errorMessage = plansErrorMessage + missinginsurancePlanNames;
            }
            //console.log('errorMessage=',errorMessage);
        }

        if(this.state.chosenCommodities===""){
            this.setState({ 'chosenCommodities': 'None' });
        }
        if(this.state.chosenStates===""){
            this.setState({ 'chosenStates': 'None' });
        }
        if(this.state.chosenInsurancePlans===""){
            this.setState({ 'chosenInsurancePlans': 'None' });
        }
        
        //handle model scenario submission
        if(scenarioReady===true){

            //this.setState({submitScenarioButtonIsDisabled:false});

            const postArray = {
                cropModelScenarioId: '', // null for create action
                scenarioAction: 'create',
                scenarioName: this.state.scenarioName,
                modelType: 'Backtesting',
                modelStatus: 'Pending to Run',
                reinsYear: this.state.reinsuranceYear,
                admYear: this.state.admYear,
                yieldCurveYear: this.state.yieldCurveYear,
                priceCurveYear: this.state.priceCurveYear,
                hccCurveYear: this.state.hccCurveYear,
                commodityCode: this.state.chosenCommoditiesCodeArray,
                stateAbbreviation: this.state.chosenStatesCodeArray,
                insurancePlanCode: this.state.chosenInsurancePlansCodeArray,
                userName: this.context.username,
                allCommodities: this.state.chosenAllCommodities,
                allStates: this.state.chosenAllStates,
                allInsurancePlans: this.state.chosenAllInsurancePlans
            };

            // call API to create Scenario
            const URL = appParameters.modelExecutionUrl;
    
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': this.context.token, // Cognito token
                'X-Api-Key': appParameters.modelExecutionApiKey
            }
        
            //console.log('create postArray=',postArray);

            axios.post(URL, postArray, {
                headers: headers
            })
            .then((response) => {
                    if(response.status===200){  // Check HTTP response.status
                        //console.log('response=',response);
                        console.log('response model status=',response.data.modelStatus);
                        if(response.data){
                                if(response.data.cropModelScenarioId){
                                    this.closeCreateScenarioModal();
                                    const scenarioArray = {
                                        "cropModelScenarioId":response.data.cropModelScenarioId,
                                        "scenarioName": this.state.scenarioName,
                                        "modelRunStatus": "Pending to Run",
                                        "reinsuranceYear": this.state.reinsuranceYear,
                                        "admYear": this.state.admYear,
                                        "yieldCurveYear": this.state.yieldCurveYear,
                                        "priceCurveYear": this.state.priceCurveYear,
                                        "hccCurveYear": this.state.hccCurveYear,
                                        "createdByUser": this.context.username, // from React Context
                                        "chosenCommodities": this.state.chosenCommodities,
                                        "chosenStates": this.state.chosenStates,
                                        "chosenPlans": this.state.chosenInsurancePlans,
                                        "url" :""
                                    }
                                    this.props.onSubmitScenario(scenarioArray); // return array to parent component to display in table
                                }else{
                                    console.log('Missing Scenario Id. Data received=',response.data);
                                    this.setState({ 'createScenarioErrorMessage': 'An error occurred when creating the Scenario. Please try again; if the error persists, please notify IT support team' });
                                    this.setState({ submitScenarioButtonIsDisabled: false });
                                    this.setState({showSubmitScenarioSpinner: false});
                                }
                        }
                        else{
                            console.log('Missing response data when creating new Scenario');                
                            this.setState({ 'createScenarioErrorMessage': 'An error occurred when creating the Scenario. Please try again; if the error persists, please notify IT support team' });
                            this.setState({ submitScenarioButtonIsDisabled: false });
                            this.setState({showSubmitScenarioSpinner: false});
                        }
                    }
            })
            //.catch(function (error) {
            .catch((error) => {

                this.setState({ 'createScenarioErrorMessage': 'An error occurred when creating the Scenario. Please try again; if the error persists, please notify IT support team' });
                this.setState({ submitScenarioButtonIsDisabled: false });
                this.setState({showSubmitScenarioSpinner: false});
    
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log('Error occurred when creating new Scenario');
                    if(error.response.data.statusDescription){
                        console.log('Error statusDescription=',error.response.data.statusDescription);
                    }
                    console.log('Error response status=',error.response.status);
                    console.log('Error response headers=',error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('error request=',error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error message', error.message);
                }
                console.log(error.config);
            });
        }else{
            this.setState({'createScenarioErrorMessage': errorMessage});
            this.setState({submitScenarioButtonIsDisabled:false});
            this.setState({showSubmitScenarioSpinner: false});
        }
    }

    componentDidMount() {
        //console.log('PrepareCreateScenario - componentDidMount');
        //setTimeout(() => {
        //}, 0)
    }

    render() {

        //console.log('PrepareCreateScenario - render');
        
        // access the React Context
        const appContext = this.context
        
        const appContentReady = this.props.appContentReady; // true|false from parent
        const { modalShow, showSubmitScenarioSpinner, submitScenarioButtonIsDisabled, optionalPicklistLoading } = this.state;
        
        // Load picklist data
        const chooseReinsuranceYearList = [];
        for(let i = parseInt(appContext.latestReinsYear) ; i >= appContext.oldestReinsYear ; i--) {
            chooseReinsuranceYearList.push({ label: String(i), value: String(i) });
        }

        const chooseAdmYearList = [];
        for(let i = parseInt(appContext.latestAdmYear) ; i >= appContext.oldestAdmYear ; i--) {
            chooseAdmYearList.push({ label: String(i), value: String(i) });
        }

        const chooseYieldCurveYearList = [];
        for(let i = parseInt(appContext.latestReinsYear) ; i >= appContext.oldestAtheniumCurveYear ; i--) {
            chooseYieldCurveYearList.push({ label: String(i), value: String(i) });
        }

        const choosePriceCurveYearList = [];
        for(let i = parseInt(appContext.latestReinsYear) ; i >= appContext.oldestAtheniumCurveYear ; i--) {
            choosePriceCurveYearList.push({ label: String(i), value: String(i) });
        }

        const chooseHccCurveYearList = [];
        for(let i = parseInt(appContext.latestReinsYear) ; i >= appContext.oldestHccCurveYear ; i--) {
            chooseHccCurveYearList.push({ label: String(i), value: String(i) });
        }

        let chooseCommoditiesList = [];
        let chooseStatesList = [];
        let chooseInsurancePlansList = [];
        //console.log('rendering nonDefaultAdmYearChosen=',this.state.nonDefaultAdmYearChosen);
        if(this.state.nonDefaultAdmYearChosen==="Y"){
            //console.log('load crops count: ',this.state.commoditiesPicklist.length);
            //console.log('load states count: ',this.state.statesPicklist.length);
            //console.log('load plans count: ',this.state.insurancePlansPicklist.length);
            chooseCommoditiesList = this.state.commoditiesPicklist;
            chooseStatesList = this.state.statesPicklist;
            chooseInsurancePlansList = this.state.insurancePlansPicklist;
        }else{
            //console.log('load default crops count: ',this.context.defaultCommoditiesPicklist.length);
            //console.log('load default states count: ',this.context.defaultStatesPicklist.length);
            //console.log('load default plans count: ',this.context.defaultInsurancePlansPicklist.length);
            chooseCommoditiesList = this.context.defaultCommoditiesPicklist;
            chooseStatesList = this.context.defaultStatesPicklist;
            chooseInsurancePlansList = this.context.defaultInsurancePlansPicklist;
        }

        // Handle onchange in picklist data
        const handleSelectedReinsuranceYear = (selectedOption) => {
            this.setState({ 'reinsuranceYear': selectedOption.value });
        }
        const handleSelectedYieldCurveYear = (selectedOption) => {
            this.setState({ 'yieldCurveYear': selectedOption.value });
        }
        const handleSelectedPriceCurveYear = (selectedOption) => {
            this.setState({ 'priceCurveYear': selectedOption.value });
        }
        const handleSelectedHccCurveYear = (selectedOption) => {
            this.setState({ 'hccCurveYear': selectedOption.value });
        }
        const handleSelectedAdmYear = (selectedOption) => {
            if(selectedOption.value!==this.state.admYear){ // compared to stored ADM Year prior to change
                this.setState({ submitScenarioButtonIsDisabled: true }); // disable scenario submit button while loading picklist
                this.setState({ 'createScenarioErrorMessage': '' });
                this.setState({ 'admYear': selectedOption.value });
                //this.context.activePicklistAdmYear = selectedOption.value; // store in app context for later comparison

                if(selectedOption.value!==this.context.latestAdmYear){
                    this.setState({ 'nonDefaultAdmYearChosen': 'Y' });
                }else{
                    this.setState({ 'nonDefaultAdmYearChosen': 'N' });
                }

                // update picklist data
                //console.log('update picklist data for ',selectedOption.value);
                this.context.commoditiesArray = [];
                this.context.statesArray = [];
                this.context.insurancePlansArray = [];
                this.setState({ 'optionalPicklistLoading': true });

                const postArray = {
                    optionalParameters: 'Y', // return ADM Year-related parameters // values: Y | blank
                    admYear: selectedOption.value // ADM Year chosen by user // values: <year> | blank   
                };
                        
                const URL = appParameters.modelPreparationUrl;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': this.context.token, // Cognito token
                    'X-Api-Key': appParameters.modelPreparationApiKey
                }
            
                axios.post(URL, postArray, {
                    headers: headers
                })
                .then((response) => {
                    if(response.status===200){
                        //console.log('POST response=',response);
                        if(response.data){
            
                            if(response.data.commodities&&response.data.insurancePlans&&response.data.states){
                                //console.log('update context with api response');
                                this.context.commoditiesArray = response.data.commodities;
                                this.context.statesArray = response.data.states;
                                this.context.insurancePlansArray = response.data.insurancePlans;

                                var optionLabel;

                                // update commodities
                                const updateCommoditiesList = [];
                                const cropCodeArray = [];
                                const cropNameArray = [];
                                console.log('update crops count: ',this.context.commoditiesArray.length);
                                updateCommoditiesList.push({ label: '** All Crops **', value: 'ALL' });
                                for (let i=0; i<this.context.commoditiesArray.length; i++) {
                                    optionLabel = this.context.commoditiesArray[i].name + " (" + this.context.commoditiesArray[i].code + ")";
                                    updateCommoditiesList.push({ label: optionLabel, value: this.context.commoditiesArray[i].code }); // store for picklist
                                    cropCodeArray.push(this.context.commoditiesArray[i].code); // store for if chosenAllCommodities
                                    cropNameArray.push(this.context.commoditiesArray[i].name); // store for if chosenAllCommodities
                                }   
                                this.setState({ 'commoditiesPicklist': updateCommoditiesList });

                                if(this.state.chosenAllCommodities==='Y'){
                                    //console.log('chosenAllCommodities on ADM year change');
                                    this.setState({ 'chosenCommoditiesCodeArray': cropCodeArray });
                                    this.setState({ 'chosenCommoditiesNameArray': cropNameArray });
                                }                        
                                //console.log('updated for adm year change - cropNameArray=',cropNameArray);

                                // update States
                                const updateStatesList = [];
                                const stateCodeArray = [];
                                const stateNameArray = [];
                                console.log('update states count: ',this.context.statesArray.length);
                                updateStatesList.push({ label: '** All States **', value: 'ALL' });
                                for (let i=0; i<this.context.statesArray.length; i++) {
                                    //optionLabel = this.context.statesArray[i].name + " (" + this.context.statesArray[i].code + ")";
                                    //optionLabel = this.context.statesArray[i].name;
                                    //updateStatesList.push({ label: optionLabel, value: this.context.statesArray[i].code }); // store for picklist
                                    stateCodeArray.push(this.context.statesArray[i].code); // store for if chosenAllState
                                    stateNameArray.push(this.context.statesArray[i].name); // store for if chosenAllStates
                                    updateStatesList.push({ label: this.context.statesArray[i].name, value: this.context.statesArray[i].code });
                                }
                                this.setState({ 'statesPicklist': updateStatesList });
                    
                                if(this.state.chosenAllStates==='Y'){
                                    //console.log('chosenAllStates on ADM year change');
                                    this.setState({ 'chosenStatesCodeArray': stateCodeArray });
                                    this.setState({ 'chosenStatesNameArray': stateNameArray });
                                }                        
                                //console.log('updated for adm year change - stateNameArray=',stateNameArray);

                                // update Insurance Plans
                                const updateInsurancePlansList = [];
                                const planCodeArray = [];
                                const planNameArray = [];
                                console.log('update plans count: ',this.context.insurancePlansArray.length);
                                updateInsurancePlansList.push({ label: '** All Insurance Plans **', value: 'ALL' });
                                for (let i=0; i<this.context.insurancePlansArray.length; i++) {
                                    optionLabel = this.context.insurancePlansArray[i].name + " (" + this.context.insurancePlansArray[i].code + ")";
                                    updateInsurancePlansList.push({ label: optionLabel, value: this.context.insurancePlansArray[i].code });
                                    planCodeArray.push(this.context.insurancePlansArray[i].code); // store for if chosenAllInsurancePlans
                                    planNameArray.push(this.context.insurancePlansArray[i].name); // store for if chosenAllInsurancePlans
                                }   
                                this.setState({ 'insurancePlansPicklist': updateInsurancePlansList });

                                if(this.state.chosenAllInsurancePlans==='Y'){
                                    //console.log('chosenAllInsurancePlans on ADM year change');
                                    this.setState({ 'chosenInsurancePlansCodeArray': planCodeArray });
                                    this.setState({ 'chosenInsurancePlansNameArray': planNameArray });                        
                                }
                                //console.log('updated for adm year change - planNameArray=',planNameArray);

                                this.setState({ 'optionalPicklistLoading': false }); // clear picklist loading icon
                                this.setState({ submitScenarioButtonIsDisabled: false }); // re-enable scenario submit button
                                this.setState({showSubmitScenarioSpinner: false});
                            }else{
                                console.log('Missing parameters for Commodities, Insurance Plans and/or US States');
                            }
                        }
                        else{
                            console.log('Missing response data');                
                        }
                    }
                })
                .catch((error) => {
                        if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log('Error occurred when getting optional parameters');
                        if(error.response.data.statusDescription){
                            console.log('Error statusDescription=',error.response.data.statusDescription);
                        }
                        console.log('Error response status=',error.response.status);
                        console.log('Error response headers=',error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log('error request=',error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error message', error.message);
                    }
                    //console.log(error.config);
                    this.setState({'createScenarioErrorMessage': 'An error occurred loading the picklists for the optional parameters. Please try again; if the error persists, please notify IT support team' });
                    this.setState({'optionalPicklistLoading': false });
                    this.setState({submitScenarioButtonIsDisabled: false});
                    this.setState({showSubmitScenarioSpinner: false});
                });

            }
        }

        const handleSelectedCommodities = (selectedOptions) => {
            console.log('handleSelectedCommodities=',selectedOptions);
            this.setState({ 'createScenarioErrorMessage': '' }); // clear error messages
            let cropCodeArray = [];
            let cropNameArray = [];
            let cropNameList = "";
            let concat = "";


            // determine whether 'select all' was chosen in the picklist
            this.setState({ 'chosenAllCommodities': '' });
            var chosenAllCommodities = "";
            for (let i=0; i<selectedOptions.length; i++) {
                if(selectedOptions[i].value==="ALL"){
                    chosenAllCommodities = "Y";
                    break;
                }
            }
            if(chosenAllCommodities==="Y"){
                // generate the data from the user context that contains the full picklist data
                //setShowCropMenu(false);
                //this.setState({ 'cropsPicklistOpen': false });
                this.setState({ 'chosenAllCommodities': 'Y' });      
                console.log('chosen All Commodities'); 
                cropNameList = "All";
                if(this.state.nonDefaultAdmYearChosen==="Y"){
                    console.log('chosen All Commodities - processing non default year'); 
                    for (let i=0; i<appContext.commoditiesArray.length; i++) {
                        cropCodeArray.push(appContext.commoditiesArray[i].code);
                        cropNameArray.push(appContext.commoditiesArray[i].name);    
                    }                    
                }else{
                    console.log('chosen All Commodities - processing default year'); 
                    for (let i=0; i<appContext.defaultCommoditiesArray.length; i++) {
                        cropCodeArray.push(appContext.defaultCommoditiesArray[i].code);
                        cropNameArray.push(appContext.defaultCommoditiesArray[i].name);    
                    }    
                }
            }else{
                // generate the data from the options selected in the picklist by the user
                for (let i=0; i<selectedOptions.length; i++) {
                    cropCodeArray.push(selectedOptions[i].value);
                    cropNameArray.push(selectedOptions[i].label);
                    if(cropNameList===""){
                        concat = selectedOptions[i].label;
                    }else{
                        concat = cropNameList.concat(', ',selectedOptions[i].label);
                    }
                    cropNameList = concat;
                }
            }
            //console.log('cropNameList=',cropNameList);
            //console.log('cropCodeArray=',cropCodeArray);
            console.log('cropNameArray=',cropNameArray);
            this.setState({ 'chosenCommodities': cropNameList });
            this.setState({ 'chosenCommoditiesCodeArray': cropCodeArray });
            this.setState({ 'chosenCommoditiesNameArray': cropNameArray });

        }
        //const [showCropMenu, setShowCropMenu] = useState(false);

        //chosenAllStates
        const handleSelectedStates = (selectedOptions) => {
            console.log('handleSelectedStates=',selectedOptions);
            this.setState({ 'createScenarioErrorMessage': '' }); // clear error messages
            let stateCodeArray = [];
            let stateNameArray = [];
            let stateNameList = "";
            let concat = "";

            // determine whether 'select all' was chosen in the picklist
            this.setState({ 'chosenAllStates': '' });
            var chosenAllStates = "";
            for (let i=0; i<selectedOptions.length; i++) {
                if(selectedOptions[i].value==="ALL"){
                    chosenAllStates = "Y";
                    break;
                }
            }
            
            if(chosenAllStates==="Y"){
                // generate the data from the user context that contains the full picklist data
                this.setState({ 'chosenAllStates': 'Y' });      
                console.log('chosen All States');              
                stateNameList = "All";
                if(this.state.nonDefaultAdmYearChosen==="Y"){
                    console.log('chosen All States - processing non default year'); 
                    for (let i=0; i<appContext.statesArray.length; i++) {
                        stateCodeArray.push(appContext.statesArray[i].code);
                        stateNameArray.push(appContext.statesArray[i].name);    
                    }
                }else{
                    console.log('chosen All States - processing default year'); 
                    for (let i=0; i<appContext.defaultStatesArray.length; i++) {
                        stateCodeArray.push(appContext.defaultStatesArray[i].code);
                        stateNameArray.push(appContext.defaultStatesArray[i].name);    
                    }    
                }
            }else{
                // generate the data from the options selected in the picklist by the user
                for (let i=0; i<selectedOptions.length; i++) {
                    stateCodeArray.push(selectedOptions[i].value);
                    stateNameArray.push(selectedOptions[i].label);
                    if(stateNameList===""){
                        concat = selectedOptions[i].label;
                    }else{
                        concat = stateNameList.concat(', ',selectedOptions[i].label);
                    }
                    stateNameList = concat;
                }    
            }
            //console.log('stateNameList=',stateNameList);
            //console.log('stateCodeArray=',stateCodeArray);
            console.log('stateNameArray=',stateNameArray);
            this.setState({ 'chosenStates': stateNameList });
            this.setState({ 'chosenStatesCodeArray': stateCodeArray });
            this.setState({ 'chosenStatesNameArray': stateNameArray });
        }

        //chosenAllInsurancePlans
        const handleSelectedInsurancePlans = (selectedOptions) => {
            console.log('handleSelectedInsurancePlans=',selectedOptions);
            this.setState({ 'createScenarioErrorMessage': '' });
            let planCodeArray = [];
            let planNameArray = [];
            let planNameList = "";
            let concat = "";

            // determine whether 'select all' was chosen in the picklist
            this.setState({ 'chosenAllInsurancePlans': '' });
            var chosenAllInsurancePlans = "";
            for (let i=0; i<selectedOptions.length; i++) {
                if(selectedOptions[i].value==="ALL"){
                    chosenAllInsurancePlans = "Y";
                    break;
                }
            }

            if(chosenAllInsurancePlans==="Y"){
                // generate the data from the user context that contains the full picklist data
                this.setState({ 'chosenAllInsurancePlans': 'Y' });      
                console.log('chosen All Insurance Plans');
                planNameList = "All";
                if(this.state.nonDefaultAdmYearChosen==="Y"){
                    console.log('chosen All Insurance Plans - processing non default year'); 
                    for (let i=0; i<appContext.insurancePlansArray.length; i++) {
                        planCodeArray.push(appContext.insurancePlansArray[i].code);
                        planNameArray.push(appContext.insurancePlansArray[i].name);    
                    }
                }else{
                    console.log('chosen All Insurance Plans - processing default year'); 
                    for (let i=0; i<appContext.defaultInsurancePlansArray.length; i++) {
                        planCodeArray.push(appContext.defaultInsurancePlansArray[i].code);
                        planNameArray.push(appContext.defaultInsurancePlansArray[i].name);    
                    }    
                }
            }else{
                // generate the data from the options selected in the picklist by the user
                for (let i=0; i<selectedOptions.length; i++) {
                    planCodeArray.push(selectedOptions[i].value);
                    planNameArray.push(selectedOptions[i].label);
                    if(planNameList===""){
                        concat = selectedOptions[i].label;
                    }else{
                        concat = planNameList.concat(', ',selectedOptions[i].label);
                    }
                    planNameList = concat;
                }                
            }

            //console.log('planNameList=',planNameList);
            //console.log('planCodeArray=',planCodeArray);
            console.log('planNameArray=',planNameArray);
            this.setState({ 'chosenInsurancePlans': planNameList });
            this.setState({ 'chosenInsurancePlansCodeArray': planCodeArray });
            this.setState({ 'chosenInsurancePlansNameArray': planNameArray });
        }

        const formGroupStyle = {
            //marginBottom: "unset";
            margin: "0px 35px 0px 0px",
            maxWidth: "200px",
            paddingLeft: "0px"
        };

        return (
        <>
            <Row className="create-scenario-button"  >
                <Col style={{display:'block'}} xs={12}>
                    { appContentReady &&
                    <Button id="create-button" style={{display:'inline',float:'right'}} variant="primary" onClick={this.initializeCreateScenarioModal}>
                        Add New Scenario
                    </Button>
                    }
                </Col>
            </Row>
            <Modal
                show={modalShow}
                backdrop="static"
                onHide={this.closeCreateScenarioModal}
                size="lg"
                aria-labelledby="create-scenario-modal"
                centered
        >
            <Modal.Header style={{color:'white',backgroundColor:'black',borderRadius:'.3rem'}} >
            <Modal.Title id="create-scenario-modal" style={{fontSize:'1.2rem'}} >
                Create Scenario
            </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container>
                <Form>
                    <Form.Group style={{margin:''}}controlId="">
                    <Form.Label>Scenario Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="scenarioName"
                        placeholder="Describe Scenario"
                        onChange={this.handleFormInputChange}
                    />
                    </Form.Group>

                    <div className="row col-xs-12 col-md-12">
                        <Form.Group className="col-md-4" style={formGroupStyle} controlId="">
                        <Form.Label>Reinsurance Year</Form.Label>
                            <Select
                                isMulti={false}
                                isClearable={false}
                                //isSearchable={false}
                                //id="selected-ry"
                                //name="reinsuranceYear"
                                className="sm-12 md-6 lg-6"
                                options = { chooseReinsuranceYearList }
                                placeholder = "Select Reinsurance Year"
                                //defaultInputValue={appContext.latestReinsYear} // to populate input text with default value
                                defaultValue={{ label: this.state.reinsuranceYear, value: this.state.reinsuranceYear }} // set the default picklist value
                                closeMenuOnSelect={true}
                                onChange={handleSelectedReinsuranceYear}
                            />
                        <Form.Text id="reinsuranceYear-sub-text" style={{color:'red'}} className="">
                        </Form.Text>
                        </Form.Group>
                        
                        <Form.Group className="col-md-4" style={formGroupStyle} >
                        <Form.Label>ADM Year</Form.Label>
                            <Select
                                isMulti={false}
                                isClearable={false}
                                //isSearchable={false}
                                //id="selected-adm"
                                //name="admYear"
                                className="sm-12 md-6 lg-6"
                                options = { chooseAdmYearList }
                                placeholder = "Select ADM Year"
                                //defaultInputValue={appContext.xxxx} // populates default text in the input field
                                defaultValue={{ label: this.state.admYear, value: this.state.admYear }} // set the default picklist value
                                closeMenuOnSelect={true}
                                onChange={handleSelectedAdmYear}
                            />
                        <Form.Text id="admYear-sub-text" style={{color:'red'}} className="">
                        </Form.Text>
                        </Form.Group>
                    </div>

                    <div className="row col-xs-12 col-md-12">
                        <Form.Group className="col-md-4" style={formGroupStyle} >
                        <Form.Label>Yield Curve Year</Form.Label>
                            <Select
                                isMulti={false}
                                isClearable={false}
                                //isSearchable={false}
                                //id="selected-"
                                //name=""
                                className="sm-12 md-6 lg-6"
                                options = { chooseYieldCurveYearList }
                                placeholder = "Select Yield Curve Year"
                                defaultValue={{ label: this.state.yieldCurveYear, value: this.state.yieldCurveYear }} // set the default picklist value
                                closeMenuOnSelect={true}
                                onChange={handleSelectedYieldCurveYear}
                            />
                        <Form.Text id="yieldCurveYear-sub-text" style={{color:'red'}} className="">
                        </Form.Text>
                        </Form.Group>
                        
                        <Form.Group className="col-md-4" style={formGroupStyle} >
                        <Form.Label>Price Curve Year</Form.Label>
                            <Select
                                isMulti={false}
                                isClearable={false}
                                //isSearchable={false}
                                //id="selected-"
                                //name=""
                                className="sm-12 md-6 lg-6"
                                options = { choosePriceCurveYearList }
                                placeholder = "Select Price Curve Year"
                                defaultValue={{ label: this.state.priceCurveYear, value: this.state.priceCurveYear }} // set the default picklist value
                                closeMenuOnSelect={true}
                                onChange={handleSelectedPriceCurveYear}
                            />
                        <Form.Text id="priceCurveYear-sub-text" style={{color:'red'}} className="">
                        </Form.Text>
                        </Form.Group>
                        
                        <Form.Group className="col-md-4" style={formGroupStyle} >
                        <Form.Label>HCC Curve Year</Form.Label>
                            <Select
                                isMulti={false}
                                isClearable={false}
                                //isSearchable={false}
                                //id="selected-"
                                //name=""
                                className="sm-12 md-6 lg-6"
                                options = { chooseHccCurveYearList }
                                placeholder = "Select HCC Curve Year"
                                defaultValue={{ label: this.state.hccCurveYear, value: this.state.hccCurveYear }} // set the default picklist value
                                closeMenuOnSelect={true}
                                onChange={handleSelectedHccCurveYear}
                            />
                        <Form.Text id="hccCurveYear-sub-text" style={{color:'red'}} className="">
                        </Form.Text>
                        </Form.Group>
                        
                    </div>

                    <Form.Group id="commodities-form-group" >
                    <Form.Label>Crops</Form.Label>
                        <Select
                            isMulti={true}
                            isLoading={optionalPicklistLoading}
                            //id="selected-commodities"
                            className="sm-12"
                            //styles = { customStyles }
                            options = { chooseCommoditiesList }
                            placeholder = "Select Crops"
                            //menuIsOpen = {this.state.cropsPicklistOpen}
                            //menuIsOpen = {showCropMenu}
                            closeMenuOnSelect={false}
                            onChange={handleSelectedCommodities}
                        />
                    </Form.Group>

                    <Form.Group id="states-form-group" >
                    <Form.Label>States</Form.Label>
                        <Select
                            isMulti={true}
                            isLoading={optionalPicklistLoading}
                            //id="selected-states"
                            className="sm-12"
                            //styles = { customStyles }
                            options = { chooseStatesList }
                            placeholder = "Select States"
                            closeMenuOnSelect={false}
                            onChange={handleSelectedStates}
                        />
                    </Form.Group>

                    <Form.Group id="insurance-plans-form-group" >
                    <Form.Label>Insurance Plans</Form.Label>
                        <Select
                            isMulti={true}
                            isLoading={optionalPicklistLoading}
                            //id="selected-plans"
                            className="sm-12"
                            //styles = { customStyles }
                            options = { chooseInsurancePlansList }
                            placeholder = "Select Insurance Plans"
                            closeMenuOnSelect={false}
                            onChange={handleSelectedInsurancePlans}
                        />
                    </Form.Group>
                    <Form.Text id="form-message-sub-text" style={{color:'red'}} className="">
                        { this.state.createScenarioErrorMessage }
                    </Form.Text>
                </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{marginRight:'10px'}} onClick={this.closeCreateScenarioModal}>Cancel</Button>
                <Button id="submit-scenario-button" style={{minWidth:'162px'}} disabled={submitScenarioButtonIsDisabled} variant="success" data-dismiss="modal" onClick={this.handleScenarioCreation}>  
                    { showSubmitScenarioSpinner &&
                    <Spinner
                        id="submit-scenario-spinner"
                        style={{marginRight:'10px'}}
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    }
                    Create Scenario</Button>
            </Modal.Footer>
        </Modal>  
        </>
        );
    }
}
  /*
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => {
                            //const { scenarioName, password } = this.state;
                            //createScenario({ scenarioName });
                            //console.log('name=',this.state.scenarioName);
                            //createScenario(this.state.scenarioName,this.state.field2);
                        }}
                        >
                        Create
                    </Button>

  */
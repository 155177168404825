// non-production
// update import reference in: amplify-configure.js, PrepareCreateScenarioCombined.js, ScenarioHistoryTable.js

const appParameters = {
    "appDomainName": "https://crop-modeling-dev.tmhcc.com/",
    //"appDomainName": "http://localhost:3000/",
    "modelPreparationUrl": "https://1p8icvfu83.execute-api.us-east-1.amazonaws.com/dev/proag-crop-modeling-preparation",
    "modelPreparationApiKey": "C3WGJyk0LTLiNRY7eiHVa3nKQ0u3nDA3Spg2aMk0",
    "modelExecutionUrl": "https://pg5eyi5j69.execute-api.us-east-1.amazonaws.com/dev/proag-crop-modeling-execution",
    "modelExecutionApiKey": "rxfaKuTQGO32qwjhdcm7T8NPugiga3wIhJHgJM5f"
};
export default appParameters;
import React, { Component, Fragment } from 'react'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import './Table.css'
import PrepareCreateScenario from './PrepareCreateScenarioCombined';
//import data from  './data/data.json'
import axios from 'axios';
import {UserContext} from './UserProvider';
import appParameters from './app-parameters-dev';

function openReport(data){
    var url = "https://app.powerbi.com/groups/me/reports/98e98b57-f144-44f4-8b3a-7ac9e06266ba/ReportSection4b6b1db5eaee1dcfab72?filter=Crop_Model_Scenarios/Crop_Model_Scenario_Id eq '"+ data.cropModelScenarioId + "'";
    //console.log('url=',url);
    window.open(url, '_blank');
}

export default class ScenarioHistoryTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tablerows: [],
            showLoadingSpinner: true,
            appContentReady: false,
            showAppContentReadyError: false
        };
        this.prependScenario = this.prependScenario.bind(this);
        this.retrieveScenarios = this.retrieveScenarios.bind(this);
    }

    // prepare the App Context
    static contextType = UserContext

    retrieveScenarios() {
        //return;
        // access the Context
        const appContext = this.context

        //var currentYear = new Date().getFullYear();
        //console.log('current year=',currentYear);   

        const postArray = {
            parameters: 'Y', // return parameters required to create a new scenario // values: Y | blank
            getModelScenarios: 'Y' // returns historical scenario data  // values: Y | blank
        };
    
        //console.log('postArray=',postArray);
        //console.log('User API Auth token for POST =',this.context.token);
    
        const URL = appParameters.modelPreparationUrl;

        /* */
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.context.token, // Cognito token
            'X-Api-Key': appParameters.modelPreparationApiKey
        }
    
        axios.post(URL, postArray, {
            headers: headers
        })
        .then((response) => {
            if(response.status===200){
                console.log('getModelScenarios POST response=',response);
                if(response.data){
    
                    this.setState({showLoadingSpinner: false});
                    if(response.data.modelScenarios){
                        //console.log('modelScenarios=',response.data.modelScenarios);
                        let modelScenarios = [];
                        modelScenarios = response.data.modelScenarios;
                        for (let i=0; i<modelScenarios.length; i++) {
                            if(modelScenarios[i].allCommodities==='Y'){
                                modelScenarios[i].chosenCommodities = 'All';
                            }
                            if(modelScenarios[i].allStates==='Y'){
                                modelScenarios[i].chosenStates = 'All';
                            }
                            if(modelScenarios[i].allInsurancePlans==='Y'){
                                modelScenarios[i].chosenPlans = 'All';
                            }
                        }
                        this.setState({tablerows: modelScenarios}); // load table content
                        this.setState({appContentReady: true});
                        appContext.appContentReady = true;
                    }else{
                        this.setState({showAppContentReadyError: true});
                        console.log('Missing Model Scenarios list');
                    }
                        /*
                        "creationDate": "", // 2021-08-20
                        */
                        if(response.data.oldestReinsYear&&response.data.latestReinsYear){
                            //console.log('oldest RY=',response.data.oldestReinsYear);
                            //console.log('latest RY=',response.data.latestReinsYear);
                            appContext.oldestReinsYear = response.data.oldestReinsYear;
                            appContext.latestReinsYear = response.data.latestReinsYear;
                        }else{
                            console.log('Missing Reinsurance Year parameters');
                        }
    
                        if(response.data.oldestAdmYear&&response.data.latestAdmYear){
                            //console.log('oldest ADM=',response.data.oldestAdmYear);
                            //console.log('latest ADM=',response.data.latestAdmYear);
                            appContext.oldestAdmYear = response.data.oldestAdmYear;
                            appContext.latestAdmYear = response.data.latestAdmYear;
                            //appContext.activePicklistAdmYear = response.data.latestAdmYear; // store in app context for later comparison
                        }else{
                            console.log('Missing ADM Year parameters');
                        }
    
                        if(response.data.oldestAtheniumCurveYear&&response.data.oldestHccCurveYear){
                            //console.log('oldestAtheniumCurveYear=',response.data.oldestAtheniumCurveYear);
                            //console.log('oldestHccCurveYear=',response.data.oldestHccCurveYear);
                            appContext.oldestAtheniumCurveYear = response.data.oldestAtheniumCurveYear;
                            appContext.oldestHccCurveYear = response.data.oldestHccCurveYear;
                        }else{
                            console.log('Missing Curve Year parameters');
                        }

                        if(response.data.commodities&&response.data.insurancePlans&&response.data.states){
                            appContext.commoditiesArray = response.data.commodities;       
                            appContext.insurancePlansArray = response.data.insurancePlans;
                            appContext.statesArray = response.data.states;

                            var optionLabel;
                            const chooseCommoditiesList = [];
                            const defaultCommoditiesArray = [];
                            chooseCommoditiesList.push({ label: '** All Crops **', value: 'ALL' });
                            for (let i=0; i<this.context.commoditiesArray.length; i++) {
                                optionLabel = this.context.commoditiesArray[i].name + " (" + this.context.commoditiesArray[i].code + ")";
                                chooseCommoditiesList.push({ label: optionLabel, value: this.context.commoditiesArray[i].code });
                                defaultCommoditiesArray.push({ name: this.context.commoditiesArray[i].name, code: this.context.commoditiesArray[i].code });
                            }   
                            appContext.defaultCommoditiesPicklist = chooseCommoditiesList; // // store in app context for use when default ADM Year picklist is required
                            appContext.defaultCommoditiesArray = defaultCommoditiesArray; // // store in app context for use when default ADM Year picklist is required and all options have been selected

                            const chooseStateList = [];
                            const defaultStatesArray = [];
                            //console.log('load states count: ',this.context.statesArray.length);
                            chooseStateList.push({ label: '** All States **', value: 'ALL' });
                            for (let i=0; i<this.context.statesArray.length; i++) {
                                chooseStateList.push({ label: this.context.statesArray[i].name, value: this.context.statesArray[i].code });
                                defaultStatesArray.push({ name: this.context.statesArray[i].name, code: this.context.statesArray[i].code });
                            }
                            appContext.defaultStatesPicklist = chooseStateList; // // store in app context for use when default ADM Year picklist is required                   
                            appContext.defaultStatesArray = defaultStatesArray; // // store in app context for use when default ADM Year picklist is required and all options have been selected

                            const chooseInsurancePlansList = [];
                            const defaultInsurancePlansArray = [];
                            //console.log('initial load plans count: ',this.context.insurancePlansArray.length);
                            chooseInsurancePlansList.push({ label: '** All Insurance Plans **', value: 'ALL' });
                            for (let i=0; i<this.context.insurancePlansArray.length; i++) {
                                optionLabel = this.context.insurancePlansArray[i].name + " (" + this.context.insurancePlansArray[i].code + ")";
                                chooseInsurancePlansList.push({ label: optionLabel, value: this.context.insurancePlansArray[i].code });
                                defaultInsurancePlansArray.push({ name: this.context.insurancePlansArray[i].name, code: this.context.insurancePlansArray[i].code });
                            }   
                            //console.log('initial chooseInsurancePlansList: ',chooseInsurancePlansList);
                            appContext.defaultInsurancePlansPicklist = chooseInsurancePlansList; // // store in app context for use when default ADM Year picklist is required
                            appContext.defaultInsurancePlansArray = defaultInsurancePlansArray; // // store in app context for use when default ADM Year picklist is required and all options have been selected

                        }else{
                            console.log('Missing parameters for Commodities, Plans and/or US States');
                        }    
                }
                else{
                    console.log('Missing response data');                
                }
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error occurred when retrieving historical scenario data and create scenario parameters');
                if(error.response.data.statusDescription){
                    console.log('Error statusDescription=',error.response.data.statusDescription);
                }
                console.log('Error response status=',error.response.status);
                console.log('Error response headers=',error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log('error request=',error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error=', error);
            }
            this.setState({showLoadingSpinner: false});
            this.setState({showAppContentReadyError: true});
        });            
    }

    componentDidMount() {

        //console.log('ScenarioHistoryTable - componentDidMount');
        
        this.retrieveScenarios();

        //setTimeout(() => {
        //}, 0)
      }

    
    prependScenario(scenarioArray) {
        //console.log('scenarioArray=',scenarioArray);
        this.state.tablerows.unshift(scenarioArray);  // prepend row for new scenario
        this.setState({tablerows: this.state.tablerows}); // re-render table in DOM
    }

    render() {

        //console.log('ScenarioHistoryTable render');

        //const { storedPropsData } = this.props;
        //const token = this.props.token;
        //console.log('ScenarioHistoryTable props=',token);

        const {showLoadingSpinner, appContentReady, showAppContentReadyError} = this.state;

        const headingstyle = {
            backgroundColor: "#5d5c5f",
            color: "white"
            };
    
        return (
            <Fragment>
                <PrepareCreateScenario appContentReady={this.state.appContentReady} onSubmitScenario={this.prependScenario} />
                { showAppContentReadyError && <span id="application-initialization-error-message" style={{ display:'inline', color:'red' }}>An error occurred loading the application. Please notify IT support</span> }
                { showLoadingSpinner &&
                <Spinner
                        id="scenarios-loading-spinner"
                        style={{display:'inline', color:'green',position:'absolute',top:'50%'}}
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                }
                { appContentReady &&
                <Table id="scenarios-table" style={{display:'inline', margin:'0px 0px'}} className="" bordered hover>
                    <thead>
                    <tr style={headingstyle}>
                        <th style={{display:'none'}}>#</th>
                        <th>Scenario Name</th>
                        <th>Model Status</th>
                        <th>Reins. Year</th>
                        <th>ADM Year</th>
                        <th>Yield Curve Year</th>
                        <th>Price Curve Year</th>
                        <th>HCC Curve Year</th>
                        <th>Selected Crops</th>
                        <th>Selected States</th>
                        <th>Selected Insurance Plans</th>
                        <th>Created By</th>
                        </tr>
                    </thead>
                    <tbody id="scenario-tbody">
                        {this.state.tablerows.map((data =>
                            <tr key={data.cropModelScenarioId} data-uri={data.url} style={{cursor:'pointer'}} onClick={() => openReport(data)  }>
                                <td style={{display:'none'}}>{data.cropModelScenarioId}</td>
                                <td style={{maxWidth:'300px'}} >{data.scenarioName}</td>
                                <td>{data.modelRunStatus}</td>
                                <td>{data.reinsuranceYear}</td>
                                <td>{data.admYear}</td>
                                <td>{data.yieldCurveYear}</td>
                                <td>{data.priceCurveYear}</td>
                                <td>{data.hccCurveYear}</td>
                                <td style={{maxWidth:'300px'}} >{data.chosenCommodities}</td>
                                <td style={{maxWidth:'300px'}} >{data.chosenStates}</td>
                                <td style={{maxWidth:'300px'}} >{data.chosenPlans}</td>
                                <td>{data.createdByUser}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                }
            </Fragment>
        )               
    }    
}